






























































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { agentModule } from '@/store/modules/agent';

@Component
export default class Avatar extends Vue {
    @Prop({ default: false, type: Boolean }) showName!: boolean;
    get user() {
        return agentModule.agent;
    }

    logout() {
        agentModule.logout();
    }

    get isAccountType() {
        return agentModule.accountTypeIsMissing;
    }

    get disaffiliated() {
        return agentModule.disaffiliated;
    }

    get account() {
        return this.user?.account;
    }

    get rol() {
        return agentModule.rol;
    }

    get disabled() {
        return (
            agentModule.informationIsMissing || agentModule.accountTypeIsMissing
        );
    }
}
