

























































































































































































































































































import { Component, Vue, PropSync } from 'vue-property-decorator';
import { appointmentModule } from '@/store/modules/appointment';
import { agentModule } from '@/store/modules/agent';
import { wordpressPageModule } from '@/store/modules/wordpress-page';

@Component
export default class Sidebar extends Vue {
    @PropSync('active', { required: true, type: Boolean }) hide!: boolean;
    previusUrl = '';

    get defaultActive() {
        if (
            this.$route.name === 'Appointment' &&
            ['agendadas', 'confirmadas', 'canceladas', 'expiradas'].includes(
                this.$route.params.state
            )
        ) {
            return '/appointment/agendadas';
        } else if (
            this.$route.name === 'Appointment' &&
            ['validadas', 'invalidadas'].includes(this.$route.params.state)
        ) {
            return '/appointment/validadas';
        } else if (
            this.$route.name === 'Minute' &&
            ['incompleta', 'completa'].includes(this.$route.params.state)
        ) {
            return '/minute/incompleta';
        } else if (this.$route.name === 'MissingInformation') {
            return '/personal-information';
        } else if (
            this.$route.name === 'BrokerAppointment' &&
            [
                'agendadas',
                'confirmadas',
                'canceladas',
                'expiradas',
                'favoritos'
            ].includes(this.$route.params.state)
        ) {
            return '/broker/seguimiento/agendadas';
        } else if (
            this.$route.name === 'BrokerAppointment' &&
            ['validadas', 'invalidadas'].includes(this.$route.params.state)
        ) {
            return '/broker/seguimiento/validadas';
        } else if (
            this.$route.name === 'BrokerMinute' &&
            ['incompleta', 'completa'].includes(this.$route.params.state)
        ) {
            return '/broker/minuta/incompleta';
        }
        return this.$route.path;
    }

    get user() {
        return agentModule.agent;
    }

    get tree() {
        return wordpressPageModule.tree;
    }

    get account() {
        return this.user?.account;
    }

    get rol() {
        return agentModule.rol;
    }

    get isMissingInformation() {
        return agentModule.informationIsMissing;
    }

    get disabled() {
        return (
            agentModule.accountTypeIsMissing || agentModule.informationIsMissing
        );
    }

    get isAccountType() {
        return agentModule.accountTypeIsMissing;
    }

    get commercialDataIsMissing() {
        return agentModule.commercialDataIsMissing;
    }

    get personalDataIsMissing() {
        return agentModule.personalDataIsMissing;
    }

    get disaffiliated() {
        return agentModule.disaffiliated;
    }

    refresh() {
        if (this.previusUrl === this.$route.path) {
            appointmentModule.index();
        }
    }

    getPages(index: string) {
        if (index === 'help-center') {
            wordpressPageModule.getPages();
        }
    }

    logout() {
        agentModule.logout();
    }

    select() {
        this.previusUrl = this.$route.path;
    }
}
