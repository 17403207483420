var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.user)?_c('el-dropdown',{staticClass:"mb-n3",attrs:{"trigger":"click"}},[_c('button',{staticClass:"el-dropdown-link border-0 bg-transparent c-p d-flex align-items-center"},[_c('el-avatar',{class:{
                'bg-white border border-light': !_vm.user.avatar
            },attrs:{"src":_vm.user.avatar}},[_c('img',{attrs:{"src":require("@/assets/img/default-user.png")}})]),(_vm.showName)?_c('h5',{staticClass:"mx-0 ml-2 text-primary"},[_vm._v(" Hola, "+_vm._s(_vm.user.nombres)+" ")]):_vm._e(),_c('i',{staticClass:"el-icon-arrow-down ml-2"})],1),_c('el-dropdown-menu',{staticClass:"py-0 dropdown-custom border-0",attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{staticClass:"bg-primary d-flex text-white align-items-center p-4 mb-2"},[_c('el-avatar',{class:{
                    'bg-white border border-light': !_vm.user.avatar
                },attrs:{"src":_vm.user.avatar}},[_c('img',{attrs:{"src":require("@/assets/img/default-user.png")}})]),_c('div',{staticClass:"ml-3"},[_c('h3',{staticClass:"m-0 l-h-20"},[_vm._v(" "+_vm._s(_vm.user.nombres)+" "+_vm._s(_vm.user.apellidoPaterno)+" "+_vm._s(_vm.user.apellidoMaterno)+" ")]),_c('p',{staticClass:"m-0 l-h-20"},[_vm._v(_vm._s(_vm.user.email))])])],1),_c('el-dropdown-item',{attrs:{"icon":"el-icon-user","disabled":_vm.isAccountType}},[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":"/personal-information","active-class":"text-primary"}},[_vm._v(" Mi cuenta ")])],1),_c('el-dropdown-item',{attrs:{"icon":"el-icon-collection","disabled":_vm.disabled}},[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":"/customer","active-class":"text-primary"}},[_vm._v(" Mi cartera de Prospectos ")])],1),_c('el-dropdown-item',{attrs:{"disabled":_vm.disabled || _vm.disaffiliated,"icon":"el-icon-office-building"}},[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":"/search","active-class":"text-primary"}},[_vm._v(" Búsqueda de Proyectos ")])],1),_c('el-dropdown-item',{attrs:{"icon":"el-icon-files","disabled":_vm.disabled}},[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{
                    name: 'Appointment',
                    params: { state: 'agendadas' }
                },"active-class":"text-primary"}},[_vm._v(" Gestión de Citas y Visitas ")])],1),_c('el-dropdown-item',{attrs:{"icon":"el-icon-document","disabled":_vm.disabled}},[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{ name: 'Minute', params: { state: 'incompleta' } },"active-class":"text-primary"}},[_vm._v(" Gestión de Minutas ")])],1),_c('el-dropdown-item',{attrs:{"icon":"el-icon-date","disabled":_vm.disabled}},[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":"/calendario-de-citas","active-class":"text-primary"}},[_vm._v(" Calendario de Citas ")])],1),(_vm.rol === 'broker')?_c('router-link',{staticClass:"text-decoration-none el-dropdown-menu__item d-block",class:{ 'is-disabled': _vm.disabled || _vm.account.approved === 1 },attrs:{"to":{ name: 'BrokerRequest' },"active-class":"text-primary"}},[_c('i',{staticClass:"fas fa-user-tie"}),_vm._v(" Panel Broker ")]):_vm._e(),_c('button',{staticClass:"el-dropdown-menu__item bg-transparent w-100 border-0 text-left",attrs:{"type":"button"},on:{"click":_vm.logout}},[_c('i',{staticClass:"el-icon-circle-close"}),_vm._v("Cerrar Sesión ")])],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }