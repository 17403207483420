import { Vue, Component } from 'vue-property-decorator';

@Component
export default class ResizeMixin extends Vue {
    created() {
        this.resize();
        window.addEventListener('resize', this.resize);
    }

    destroyed() {
        window.removeEventListener('resize', this.resize);
    }

    resize() {
        console.log('size');
    }
}
