



























































































































import { Component, Mixins } from 'vue-property-decorator';
import Sidebar from '@/components/Sidebar.vue';
import Avatar from '@/components/Header/Avatar.vue';
import { agentModule } from '@/store/modules/agent';
import ResizeMixin from '@/mixins/size';

@Component({
    components: { Sidebar, Avatar }
})
export default class Default extends Mixins(ResizeMixin) {
    activeMenu = false;
    mobile = true;
    banner = true;

    get user() {
        return agentModule.agent;
    }

    get account() {
        if (this.$route.name === 'AccountType') return '';
        let value = '';
        switch (agentModule.rol) {
            case 'broker':
                value = 'Broker';
                break;
            case 'independiente':
                value = 'Independiente';
                break;
            case 'asociado':
                value = 'Asociado a un broker';
                break;
        }
        return value;
    }

    mounted() {
        this.resize();
    }

    resize() {
        this.mobile = window.innerWidth <= 992;
    }
}
